import { Injectable } from "@angular/core";
import { SessionService } from "./session.service";
import { MatDrawerMode } from "@angular/material/sidenav";
import { Subject } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class UserPreferencesService {

    private toolbarPinnedPreferencesUpdatedSubject = new Subject<string[]>();
    toolbarPinnedPreferencesUpdatedSubject$ = this.toolbarPinnedPreferencesUpdatedSubject.asObservable();

    constructor(private sessionService: SessionService) {
        this.sessionService.userPreferencesUpdatedSubject$.subscribe(() => {
            let pinnedToolbarBoardKeys = this.getToolbarPinnedPreferences();
            this.toolbarPinnedPreferencesUpdatedSubject.next(pinnedToolbarBoardKeys);
        });
    }

    getUserRecordManagerColumnList(columnDefSetting: string): string[] | null {
        if (columnDefSetting) {
            const configurationItem = 'RecordManager' + columnDefSetting;
            return this.sessionService.getUserConfigurationItem(configurationItem, null);
        } else {
            return null;
        }
    }

    saveUserRecordManagerColumnList(columnDefSetting: string, columnList: string[] | null): Promise<any> {
        if (columnDefSetting) {
            const configurationItem = 'RecordManager' + columnDefSetting;
            this.sessionService.setUserConfigurationItem(configurationItem, columnList);
            return this.sessionService.saveUserPreference();
        } else {
            return Promise.resolve();
        }
    }

    getToolbarPreferences(): Promise<any> {
        return this.sessionService.getUserConfigurationItemAsync(TOOLBAR_PREFERENCES, null);
    }

    saveToolbarPreferences(toolbarPreferences: any): Promise<any> {
        this.sessionService.setUserConfigurationItem(TOOLBAR_PREFERENCES, toolbarPreferences);
        return this.sessionService.saveUserPreference();
    }

    getToolbarPinnedPreferences(): string[] {
        return this.sessionService.getUserConfigurationItem(TOOLBAR_PINNED_PREFERENCES, null);
    }

    updateToolbarPinnedPreference(boardKey: string, pinned: boolean): void {
        let pinnedToolbarBoardKeys = this.getToolbarPinnedPreferences();
        if (!pinnedToolbarBoardKeys) {
            pinnedToolbarBoardKeys = [];
        }

        const boardIndex = pinnedToolbarBoardKeys?.indexOf(boardKey);
        if (pinned) {
            if (boardIndex < 0) {
                pinnedToolbarBoardKeys.push(boardKey);
            }
        } else {
            if (boardIndex >= 0) {
                pinnedToolbarBoardKeys.splice(boardIndex, 1);
            }
        }

        this.sessionService.setUserConfigurationItem(TOOLBAR_PINNED_PREFERENCES, pinnedToolbarBoardKeys);
        this.sessionService.saveUserPreference().then(
            () => {
                // this.toolbarPinnedPreferencesUpdatedSubject.next(pinnedToolbarBoardKeys);
            }
        );
    }
}

const PORTAL_BASE_KEY = 'PORTAL4_';
const TOOLBAR_PREFERENCES = PORTAL_BASE_KEY + 'TOOLBAR_PREFERENCES';
const TOOLBAR_PINNED_PREFERENCES = PORTAL_BASE_KEY + 'TOOLBAR_PINNED_PREFERENCES';